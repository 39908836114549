<template>
  <div class="flex h-screen">
    <div class="w-96 flex items-center">
      <div class="p-6 w-full">
        <p class="text-6xl text-center mb-6 font-bold">EXSERVICE</p>
        <el-form ref="form" :rules="rules" :model="form" @submit.prevent.native>
          <el-form-item prop="username">
            <el-input
              @keyup.13.native="passwordActive"
              v-model="form.username"
              ref="username"
              clearable
              placeholder="Loginni kiriting"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <!-- @keyup.13.native="Login" -->
            <el-input
              v-model="form.password"
              ref="password"
              clearable
              show-password
              placeholder="Parolni kiriting"
            ></el-input>
          </el-form-item>
          <el-form-item class="text-center">
            <button
              class="bg-blue-500 w-40 h-10 rounded text-white text-lg mx-auto shadow-xl"
              @click="Login"
            >
              Kirish
            </button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="flex-1 bg-gradient-to-r from-blue-500 to-green-500">
      <img
        src="@/assets/img/login-2.jpg"
        class="w-full h-full object-right object-cover"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",
  data: () => ({
    form: {
      username: "",
      password: "",
    },
    rules: {
      username: [
        {
          required: true,
          message: "Iltimos loginni kiriting",
          trigger: "blur",
        },
      ],
      password: [
        {
          required: true,
          message: "Iltimos parolni kiriting",
          trigger: "blur",
        },
      ],
    },
  }),
  mounted() {
    localStorage.clear();
  },
  methods: {
    passwordActive() {
      if (this.form.username) {
        this.$refs.password.focus();
      }
    },
    Login() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          axios
            .post("auth/", this.form)
            .then((response) => {
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("expire_time", response.data.expire_time);
              localStorage.setItem("id", response.data.user.id);
              localStorage.setItem("username", response.data.user.username);
              localStorage.setItem("role", response.data.user.role);
              this.$router.push("/");
              this.$notify({
                title: this.$t("success"),
                message: this.$t("welcome"),
                duration: 7000,
                type: "success",
              });
            })
            .catch((er) => {
              console.log(er)
              this.$notify.error({
                title: "Xatolik",
                message: this.$t("password_incorrect"),
              });
            });
        } else {
          this.form.username
            ? this.$refs.password.focus()
            : this.$refs.username.focus();
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
